import * as React from "react"
import styled from "styled-components";


import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/Title';
import Image from '../components/Image';

const Box = styled.div`
  @media(max-width: 1000px){
    padding: 0 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  text-align: justify;

  p{
    margin-right:20px;
  }

  @media(max-width: 1000px){
    flex-direction: column;
    align-items: center;

    img{
      margin-top:20px;
      width:80%;
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Nuestra Empresa" />
    <Box>
      <Title title='Nuestra' secondTitle='Empresa' />
  
      <p>Es una empresa dedicada a entregar soluciones. Hasta ahora se ha centrado prioritariamente en el campo de las cadenas de negocios, con experiencias y capacidad para efectuar trabajos de cualquier nivel y en cualquier ámbito.
      </p>
      <Container>
        <p>Ha desarrollado sus actividades en óptimas condiciones, desde el diseño, fabricación y mantención, también hemos desarrollando trabajos en el área de obras civiles menores como son la construcción, reparación y montaje de cortinas metálicas, pintura, gasfitería, electricidad y mantención de obras civiles. 
          <br/> <br/>Entre otros servicios realizamos el montaje y la mantención de material publicitario desde Rancagua hasta Puerto Montt para la Empresa SUPERMERCADOS  UNIMARC , MAXIAHORRO ,MAYORISTA 10,DIPAC, ALVI ,SANTA ISABEL, JUMBO, PHILIP MORRIS CHILE LTDA,CRUZ VERDE SA, PETRIZZIO, VENDOME, OK MARKET construcción y montaje de estructuras de aluminio y vidrios ,para distintas entidades publicas. 
          <br/> Durante este periodo, ESOV se ha caracterizado siempre por su continuo desarrollo, adaptación y responsabilidad a las modernas tecnologías que paralelamente han ido naciendo, situándose siempre a la vanguardia de las empresas y microempresas.
        </p>
        <Image srcName="banners_cert2.png" width="400" height="300" alt="Certificacion NCH2769 NCH2770" />
      </Container>
    </Box>
  </Layout>
)

export default IndexPage
